import React from "react";
import { useLocation } from "react-router-dom";
import { EventContext } from "../../common/contexts/event";
import { useEventContextListener } from "../../common/hooks/useeventcontextlistener";
import { useEventListener } from "../../common/hooks/uselistener";
import { useObservable, useSubscription } from "../../common/hooks/useobservable";
import { IFetchCompleteEvent } from "../../common/utilities/fetch";
import { defer } from "../../common/utilities/promise";
import { getScenarioEvent, startScenario } from "../../common/utilities/scenario";
import { SessionContext } from "../contexts/session";

/**
 * Logs data associated with the user's activity browsing photos.
 */
export function useLogBrowse(): void {
  const eventContext = React.useContext(EventContext);
  const sessionContext = React.useContext(SessionContext);

  const [visible, setVisible] = useObservable(document.visibilityState !== "hidden");
  const visibleValue = useSubscription(visible);

  const countAllRequests = React.useRef(0);
  const countDelete = React.useRef(0);
  const countThumbnailFetch = React.useRef(0);
  const countThumbnailFromCache = React.useRef(0);
  const countUpload = React.useRef(0);

  const throttleCountFromQuota = React.useRef(0);
  const throttleCountFromServiceUnavailability = React.useRef(0);

  const _start = React.useCallback(start, [eventContext, sessionContext]);

  useEventContextListener("fetchComplete", React.useCallback(handleFetchComplete, []));
  useEventListener(document, "visibilitychange", React.useCallback(handleVisibilityChange, [setVisible]));
  const location = useLocation();

  React.useEffect(() => {
    if (visibleValue) {
      return _start();
    }
  }, [
    _start,
    location, // New scenario when user navigates to a different location
    visibleValue
  ]);

  /**
   * Starts a new telemetry scenario.
   * @returns Callback for ending the scenario
   */
  function start(): () => void {
    const { promise, resolve } = defer<void>();

    const scenario = startScenario(
      promise,
      {
        properties: { pivot: sessionContext.getPage().current },
        scenarioName: "browse",
        scenarioType: "useraction"
      },
      (scenarioDetails) => {
        const scenarioEvent = getScenarioEvent("scenarioComplete", scenarioDetails);
        eventContext.dispatchEvent("scenarioComplete", scenarioEvent);
        eventContext.dispatchEvent("telemetryAvailable", scenarioEvent);
      }
    );

    let peakFetches = 0;
    let countAtStart = countAllRequests.current;
    const peakFetchesInterval = window.setInterval(() => {
      const fetchesInSecond = countAllRequests.current - countAtStart;
      peakFetches = Math.max(peakFetches, fetchesInSecond);

      // Reset count for the next interval execution
      countAtStart = countAllRequests.current;
    }, 1000);

    return () => {
      scenario.log({
        deleteCount: countDelete.current,
        peakFetchesPerSecond: peakFetches,
        quotaThrottleCount: throttleCountFromQuota.current,
        serviceThrottleCount: throttleCountFromServiceUnavailability.current,
        thumbnailFetchCount: countThumbnailFetch.current,
        thumbnailFromCacheCount: countThumbnailFromCache.current,
        totalNetworkRequests: countAllRequests.current,
        uploadCount: countUpload.current
      });

      countAllRequests.current = 0;
      countDelete.current = 0;
      countThumbnailFetch.current = 0;
      countThumbnailFromCache.current = 0;
      countUpload.current = 0;
      throttleCountFromQuota.current = 0;
      throttleCountFromServiceUnavailability.current = 0;

      clearInterval(peakFetchesInterval);

      resolve();
    };
  }

  function handleFetchComplete(event: IFetchCompleteEvent): void {
    const { name } = event;

    if (name === "cachedDownload") {
      countThumbnailFromCache.current++;
    } else {
      countAllRequests.current++;

      if (name === "downloadPhoto") {
        countThumbnailFetch.current++;
      } else if (name === "deletePhoto") {
        countDelete.current++;
      } else if (name === "uploadPhoto") {
        countUpload.current++;
      }
    }

    const httpStatus = fromValue(event.telemetryProperties.httpStatus);
    if (httpStatus === 429) {
      throttleCountFromQuota.current++;
    } else if (httpStatus === 503) {
      throttleCountFromServiceUnavailability.current++;
    }
  }

  function handleVisibilityChange(): void {
    setVisible(document.visibilityState !== "hidden");
  }

  function fromValue(value: string | number | boolean | undefined): number | undefined {
    return typeof value === "number" ? value : typeof value === "string" ? Number.parseInt(value) : undefined;
  }
}
