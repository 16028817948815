import { AppInsightsCore, IExtendedConfiguration, IExtendedTelemetryItem, ITelemetryItem } from "@microsoft/1ds-core-js";
import { PostChannel } from "@microsoft/1ds-post-js";
import { PrivacyGuardPlugin } from "@microsoft/1ds-privacy-guard-js";
import { IPropertyConfiguration, PropertiesPlugin } from "@microsoft/1ds-properties-js";
import React from "react";
import { EventContext } from "../../common/contexts/event";
import { useInterval } from "../../common/hooks/usetimeout";
import { getCookie } from "../../common/utilities/browser";
import { ITelemetryEvent } from "../../common/utilities/platformdispatch";
import { SessionContext } from "../contexts/session";
import { populateFailedSubScenarios } from "../utilities/customerpromise";
import { getFailureType } from "../utilities/error";

const telemetryTypePrefixMapping: { [key: string]: string } = {
  commandComplete: "Qos_extraData",
  customerPromise: "CustomerPromise_extraData",
  engagement: "Engagement_extraData",
  exception: "Qos_extraData",
  fetchComplete: "Qos_extraData",
  fetchStart: "Qos_extraData",
  operationComplete: "Engagement_extraData",
  performance: "RUMOneDataUpload_dictionary",
  scenarioComplete: "Engagement_extraData",
  userAction: "Engagement_extraData"
};

const batchEventTypes = new Set(["cachedDownload", "scrollWheelDown", "scrollWheelUp", "scrubberDrag"]);
const emptyItems: ITelemetryItem[] = [];
let failureIndex = 0;

function combineProperties(event: ITelemetryEvent, target: { [property: string]: any }): void {
  // Only combine component renders
  if (event.scenarioType === "componentRender" && event.properties) {
    for (const key of Object.keys(event.properties)) {
      // Having duplicate telemetry properties is most likely a mistake
      // only the first encountered property will be sent for telemetry
      // Please consider picking a different property name
      // The "pillar" property in child scenarios is used for customer promise telemetry and can be ignored in this instance
      if (target[key] != null && key !== "pillar") {
        console.warn(
          `Duplicate telemetry property ${key} found, existing value ${target[key]} new value ${event.properties[key]}, skipping it. Event is `,
          event
        );
        continue;
      }

      target[key] = event.properties[key];
    }
  }

  event.children?.forEach((child) => combineProperties(child, target));
}

function prepareExtraData(action: string, data: { [property: string]: any }): any {
  const preparedExtraData: { [key: string]: any } = {};

  for (const key in data) {
    const value = data[key];

    if (value !== undefined) {
      preparedExtraData[`${telemetryTypePrefixMapping[action]}_${key}`] = typeof value === "object" ? JSON.stringify(value) : value;
    }
  }

  return preparedExtraData;
}

export function useOneDS(): ((event: ITelemetryEvent) => void) | void {
  const eventContext = React.useContext(EventContext);
  const sessionContext = React.useContext(SessionContext);

  const batchedEvents = React.useRef<{ [key: string]: ITelemetryEvent }>({});

  const [oneDsLogger] = React.useState(() => new AppInsightsCore());

  const { setInterval } = useInterval();

  // Send batched items every 10 seconds (we may lose some on unload its ok).
  setInterval(sendBatchedItems, 10000);

  // Ensure an ariaToken has been defined, if not telemetry events
  if (sessionContext.ariaToken) {
    try {
      // Based on the users current authentication and migration state record the details.
      const authenticated = sessionContext.authenticated();
      const clientName = sessionContext.accountType === "business" ? "ODBPhotos Web" : "ODCPhotos Web";
      const environment =
        sessionContext.accountType === "business"
          ? "ODBPhotos"
          : `ODCPhotos_${sessionContext.ring === "dogfood" ? "DF" : sessionContext.environment === "production" ? "Prod" : "Dev"}`;
      const workload = sessionContext.accountType === "business" ? "ODBPhotos" : "ODCPhotos";

      const clientVersion = sessionContext.clientVersion;

      // Setup the properties plugin with all the static fields for each event.
      const propertiesPlugin = new PropertiesPlugin();
      propertiesPlugin.setProperty("AppInfo.Manifest", "photos.manifest"); // The manifest comes from coreConfig.ts on the server.
      propertiesPlugin.setProperty("AppInfo.Version", clientVersion);
      propertiesPlugin.setProperty("BrowserIsMobile", (!!navigator.userAgent.toLowerCase().match(/mobile/i)).toString());
      propertiesPlugin.setProperty("BrowserUserAgent", navigator.userAgent);
      propertiesPlugin.setProperty("ClientName", clientName);
      propertiesPlugin.setProperty("Environment", environment);
      propertiesPlugin.setProperty("IsAuthenticated", authenticated && sessionContext.userProfile ? "1" : "0");
      propertiesPlugin.setProperty("Workload", workload);
      propertiesPlugin.setProperty("SessionId", sessionContext.session);

      const propertiesPluginConfig: IPropertyConfiguration = {
        populateBrowserInfo: true
      };

      var privacyGuard: PrivacyGuardPlugin = new PrivacyGuardPlugin();
      const privacyGuardPluginConfig = {
        notificationEventName: "ev_privacyguard",
        iKey: sessionContext.ariaToken,
        enabled: true,
        disableAdvancedScans: true,
        scanForUrls: false
      };

      const coreConfig: IExtendedConfiguration = {
        channels: [[new PostChannel()]],
        cookieCfg: { enabled: false },
        endpointUrl: sessionContext.telemetryEndpoint,
        instrumentationKey: sessionContext.ariaToken,
        extensions: [propertiesPlugin],
        extensionConfig: {
          [propertiesPlugin.identifier]: propertiesPluginConfig,
          [privacyGuard.identifier]: privacyGuardPluginConfig
        }
      };

      // Initialize the oneDS SDK
      oneDsLogger.initialize(coreConfig, [privacyGuard]);

      // Add client version to the propertiesPlugin context if available.
      const pluginContext = propertiesPlugin.getPropertiesContext();
      if (pluginContext?.app) {
        pluginContext.app.ver = clientVersion;
      }

      if (authenticated && sessionContext.userId) {
        // istanbul ignore else - Con't figure out how to remove BigInt from window.
        const userId = "BigInt" in window ? BigInt.asIntN(64, BigInt(`0x${sessionContext.userId}`)).toString(10) : sessionContext.userId;
        propertiesPlugin.setProperty("UserInfo_Id", userId);

        // Add this to the propertiesPlugin context if available.
        const pluginContext = propertiesPlugin.getPropertiesContext();
        if (pluginContext && pluginContext.user) {
          propertiesPlugin.getPropertiesContext().user.localId = `m:${userId}`;
        }
      }

      // Return the event reporting function to the caller
      return (event: ITelemetryEvent): void => {
        if (batchEventTypes.has(event.name)) {
          // Add the current event to the batch
          const { action, name, result } = event;
          const itemKey = `${action}-${name}${result?.status !== "rejected" ? "" : `-${failureIndex++}`}`;
          const batchedEvent = batchedEvents.current[itemKey];

          if (!batchedEvent) {
            batchedEvents.current[itemKey] = { ...event, count: 1 };
          } else {
            batchedEvent.count++;
          }
        } else {
          sendItems(getItemsFromEvent(event));
        }
      };
    } catch (error) {
      eventContext.dispatchEvent("telemetryAvailable", { action: "exception", name: "1dsInitialization", error });
    }
  }

  function getItemsFromEvent(event: ITelemetryEvent): ITelemetryItem[] {
    const eventType = event.action.toLowerCase();
    const page = sessionContext.getPage();

    const commonProperties = {
      AccountType:
        sessionContext.accountType === "business"
          ? "Business"
          : sessionContext.authenticated()
            ? (event.telemetryProperties?.migrated ?? sessionContext.migrated)
              ? "ConsumerOnSPO"
              : "Consumer"
            : "ConsumerAnonymous"
    };

    switch (eventType) {
      case "engagement": {
        const { action, scenarioName, name, ...extraData } = event;
        const data = {
          ...commonProperties,
          CurrentPage: page.current,
          Name: `PhotosEngagement.${scenarioName || name}`,
          ...prepareExtraData(action, extraData)
        };

        return [{ name: "ev_engagement", data }];
      }

      case "exception": {
        const { action, colno, error, filename, lineno, name, scenarioName, stack, ...extraData } = event;

        if (name === "unhandledException") {
          const data = {
            ...commonProperties,
            Name: `OneDrivePhotos.unhandledException`,
            UnhandledError_col: colno,
            UnhandledError_filename: filename,
            UnhandledError_line: lineno,
            UnhandledError_message: error,
            UnhandledError_stack: stack,
            ...prepareExtraData(action, extraData)
          };

          return [{ name: "ev_unhandlederror", data }];
        } else {
          const ResultCode = error?.code || error || "noErrorCode";
          const Error = error?.message || error;
          const data = {
            ...commonProperties,
            Error,
            Name: `OneDrivePhotos.${scenarioName || name}`,
            ResultCode,
            ResultType: getFailureType(ResultCode, Error),
            WebLog_EventType: "End",
            ...prepareExtraData(action, extraData)
          };

          return [{ name: "ev_qos", data }];
        }
      }

      case "fetchcomplete": {
        const { action, count, duration, endTime, name, result, scenarioName, startTime, telemetryProperties, url: Api_url } = event;
        const { migrated, httpStatus, ms_cv, spRequestGuid, spRequestDuration, ...extraProperties } = telemetryProperties || {};

        // Convert the result into the proper set of reported properties.
        const resultStatus = {
          ResultType: "Success"
        };

        if (result?.status === "rejected") {
          const ResultCode = result.reason?.error?.code || result?.reason?.message || "noResultCode";
          const Error = result.reason?.error?.message || result?.reason?.message;

          Object.assign(resultStatus, {
            Error,
            ResultCode,
            ResultType: getFailureType(ResultCode, Error, result.reason)
          });
        }

        // We want to add NetworkTime to the extraProperties if SpRequestDuration is available.
        if (spRequestDuration) {
          try {
            extraProperties.spRequestDuration = parseInt(spRequestDuration);
            extraProperties.networkTime = parseInt(duration) - extraProperties.spRequestDuration;
          } catch {}
        }

        // Merge the extraProperties into the extraData to build a full set of
        // telememtry values to report in the ExtraData.
        const extraData = { ...extraProperties, count, endTime, startTime };
        const data = {
          ...commonProperties,
          Api_url,
          CorrelationVector: ms_cv || spRequestGuid,
          Duration: duration?.toString(),
          ExtraMetrics_httpStatus: httpStatus,
          Name: `OneDrivePhotos.${scenarioName || name}`,
          WebLog_EventType: "End",
          ...resultStatus,
          ...prepareExtraData(action, extraData)
        };

        return [{ name: "ev_qos", data }];
      }

      case "fetchstart": {
        const { action, options, name, scenarioName, url: Api_url, ...extraData } = event;

        const data = {
          ...commonProperties,
          Api_url,
          Name: `OneDrivePhotos.${scenarioName || name}`,
          WebLog_EventType: "Start",
          ...prepareExtraData(action, extraData)
        };

        return [{ name: "ev_qos", data }];
      }

      case "commandcomplete": {
        const { action, duration, name, result, scenarioName, ...extraData } = event;
        const resultStatus = {
          ResultType: "Success"
        };

        if (result?.status === "rejected") {
          const resultCode = result.reason?.error?.code || result?.reason?.message || "noResultCode";
          const error = result.reason?.error?.message || result?.reason?.message;

          Object.assign(resultStatus, {
            Error: error,
            ResultCode: resultCode,
            ResultType: result.reason?.expectedFailure ? "ExpectedFailure" : getFailureType(resultCode, error)
          });
        }

        const qosData = {
          ...commonProperties,
          Name: `OneDrivePhotos.${scenarioName || name}`,
          Duration: duration?.toString(),
          WebLog_EventType: "End",
          ...resultStatus,
          ...prepareExtraData(action, extraData)
        };

        const engagementData = {
          ...commonProperties,
          Name: `PhotosCommand.${scenarioName || name}`,
          ...prepareExtraData(action, {
            duration,
            ResultType: resultStatus.ResultType === "Failure" ? "Failure" : "Success",
            ...(result?.status === "rejected" ? { error: JSON.stringify(result?.reason?.error || {}) } : {})
          })
        };

        return [
          { name: "ev_qos", data: qosData },
          { name: "ev_engagement", data: engagementData }
        ];
      }

      case "operationcomplete":
      case "scenariocomplete": {
        const { action, children, duration, properties, scenarioName, name } = event;

        let failedSubScenarios = populateFailedSubScenarios(event, []);

        let data: any = {
          ...commonProperties,
          CurrentPage: page.current,
          Name: `PhotosScenario.${scenarioName || name}`,
          PreviousPage: page.previous
        };

        const resultType = failedSubScenarios.length > 0 ? "Failure" : "Success";
        const error = failedSubScenarios.length > 0 ? JSON.stringify(failedSubScenarios[0]) : undefined;

        const mergedProperties: any = { ...properties };

        // walk the scenario tree, pulling up properties into the root properties
        // we already copied root, so start with children
        children?.forEach((child) => combineProperties(child, mergedProperties));

        // promote the topmost scenario as the real scenario
        if (scenarioName === "photosAppLoad" && children?.length && children[0].scenarioName) {
          // We take the sub-scenario's name but keep the root duration and error info
          data.Name = `PhotosScenario.${children[0].scenarioName}`;

          // We want to know that we promoted this scenario, as this means we performed a full page load instead of a route switch
          mergedProperties.pageLoad = true;
        }

        Object.assign(
          data,
          prepareExtraData(action, {
            ...mergedProperties,
            duration,
            ResultType: resultType,
            error
          })
        );

        return [{ name: "ev_engagement", data }];
      }

      case "useraction": {
        const { action, scenarioName, name, ...extraData } = event;
        const data = {
          ...commonProperties,
          Name: `PhotosAction.${scenarioName || name}`,
          CurrentPage: page.current,
          PreviousPage: page.previous,
          ...prepareExtraData(action, extraData)
        };

        return [{ name: "ev_engagement", data }];
      }

      case "experimentevaluation": {
        const { name, variant, defaultValue = "Default" } = event;

        const data = {
          ...commonProperties,
          CurrentPage: page.current,
          Experiment: name,
          IsInRamp: variant !== defaultValue,
          Variant: variant
        };

        return [{ name: "ev_experiment", data }];
      }

      case "customerpromise": {
        const { duration, properties, scenarioName, pillar, resultType, veto } = event;

        // If the scenario/operation is tracked for ASHA, send a customer promise telemetry
        const data = {
          ...commonProperties,
          CustomerPromise_extraData_excludeTDC: properties?.excludeTDC,
          CustomerRing: sessionContext.ring,
          Duration: duration?.toString(),
          Pillar: pillar,
          ResultType: resultType,
          Scenario: scenarioName,
          WebLog_EventType: "End"
        };

        if (resultType === "Failure") {
          Object.assign(data, {
            ErrorCode: veto.errorCode,
            Veto: veto.name
          });
        }

        return [{ name: "ev_customerpromise", data }];
      }

      case "performance": {
        const { action, duration, properties, scenarioName, name, ...extraData } = event;
        const data = {
          ...commonProperties,
          CurrentPage: page.current,
          Name: `PhotosPerf.${scenarioName || name}`,
          RUMOneDataUpload_dictionary_EUPL: duration,
          ...prepareExtraData(action, extraData)
        };

        return [{ name: "ev_rumonedataupload", data }];
      }

      default:
        return emptyItems;
    }
  }

  function sendBatchedItems() {
    const batchedItems: IExtendedTelemetryItem[] | ITelemetryItem[] = [];

    // Add the items generated from this event to the resulting items batch
    for (const key of Object.keys(batchedEvents.current)) {
      batchedItems.push(...getItemsFromEvent(batchedEvents.current[key]));
    }

    batchedEvents.current = {};
    sendItems(batchedItems);
  }

  function sendItems(logItems: IExtendedTelemetryItem[] | ITelemetryItem[]) {
    // istanbul ignore next - DEVELOPMENT: Show OneDS telemetry logs in the console.
    if (process.env.NODE_ENV !== "test" && (sessionContext.environment === "development" || getCookie("Development"))) {
      if (logItems.length) {
        for (let index = 0; index < logItems.length; index++) {
          console.debug(logItems[index].name, logItems[index]);
        }
      }
    }

    for (let logItem of logItems) {
      oneDsLogger.track(logItem);
    }
  }
}
